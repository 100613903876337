import { useRoute } from '#imports'

/**
 * Retrieve the given query param value.
 *
 * If several occurrences of this param are set, return the first one.
 *
 * @example /?name=Bob
 * useQueryParam('name') // Bob
 *
 * @example /?name=Bob&name=Jane
 * useQueryParam('name') // Bob
 *
 * @example /
 * useQueryParam('name') // null
 */
export function useQueryParam(param: string): string | null {
  const { query } = useRoute()
  const value = query[param]

  if (value === undefined) {
    return null
  }

  if (Array.isArray(value)) {
    return value[0]
  }

  return value
}
