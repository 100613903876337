<template>
  <div
    class="relative overflow-scroll px-24 pb-[140px] md:flex md:grow md:flex-row md:items-stretch md:justify-stretch md:p-0"
  >
    <RevLoadingScreen
      class="bg-surface-default-mid fixed inset-0 z-10 w-full md:absolute md:right-[440px]"
      data-qa="loading-screen"
      data-test="loading-screen"
      :text="i18n(translations.loading)"
    />
  </div>
</template>

<script lang="ts" setup>
import { useLogin } from '@backmarket/nuxt-layer-oauth/useLogin'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import translations from './Login.translations'

const i18n = useI18n()
const logger = useLogger()

useLogin({
  redirectionPathname: '/auth/login',
  onError(error) {
    if (error instanceof Error) {
      logger.error('Something failed while logging in', {
        error,
        owners: ['bot-squad-identity'],
      })
    }
  },
})
</script>
