import { oauthAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

export function useSession(accessToken: string) {
  return $httpFetch(oauthAPI.getSession, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
}
