import { oauthAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

type Options = {
  refreshToken: string
  clientId: string
}

export function useRevokeToken(endpoint: string, options: Options) {
  const body = new URLSearchParams({
    client_id: options.clientId,
    token: options.refreshToken,
    token_type_hint: 'refresh_token',
  })

  return $httpFetch(oauthAPI.revokeToken, {
    path: endpoint,
    body,
  })
}
