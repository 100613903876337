import { createHttpEndpoint } from '../../utils'

/** @see {@link https://www.ory.sh/docs/hydra/reference/api#tag/oAuth2/operation/oauth2TokenExchange} */
type GetTokenResponseBody = {
  accessToken: string
  refreshToken: string
}

/**
 * Get access and refresh tokens.
 */
export const getTokens = createHttpEndpoint<GetTokenResponseBody>({
  method: 'POST',
  path: '/oauth2/token',
  operationId: 'getOauthTokens',
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
  },
  transformResponseToCamelCase: true,
})

/**
 * Revoke the given refresh token.
 */
export const revokeToken = createHttpEndpoint({
  method: 'POST',
  path: '/oauth2/revoke',
  operationId: 'revokeOauthToken',
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
  },
})
