import { createHttpEndpoint } from '../../utils'

/**
 * Exchange an access token with a session.
 */
export const getSession = createHttpEndpoint({
  method: 'GET',
  path: '/auth/gateway',
  operationId: 'getOauthSession',
})
