import { oauthAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

type Options = {
  code: string
  clientId: string
  redirectUrl: URL
  verifier: string
}

export async function useTokens(endpoint: string, options: Options) {
  const body = new URLSearchParams({
    grant_type: 'authorization_code',
    code: options.code,
    client_id: options.clientId,
    redirect_uri: options.redirectUrl.href,
    code_verifier: options.verifier,
  })

  const payload = await $httpFetch(oauthAPI.getTokens, {
    path: endpoint,
    body,
  })

  return {
    accessToken: payload.accessToken,
    refreshToken: payload.refreshToken,
  }
}
